<template>
  <DrawerModal>
    <section>
      <div class="font-light font-suiza-regular">
        <template v-if="sent">
          <div
            :class="[
              hasErrors ? 'mb-[22px] md:mb-3' : 'mb-3',
              'px-[30px] pt-10',
            ]"
          >
            <h2
              class="2xl:text-3xl md:text-[26px] text-2xl font-normal font-[#0F0F0F]"
            >
              {{ $t('common.forgot_password_email_sent') }}
            </h2>
            <p class="text-[#404040] mt-2 text-sm xl:text-base">
              {{ $t('common.forgot_password_email_sent_steps') }}
            </p>
          </div>

          <div class="pt-2 px-[30px] absolute w-full bottom-10">
            <button
              @click.prevent="$emit('close')"
              class="w-full bg-primary-500 rounded-[10px] font-medium text-white py-[10px] md:py-2 md:px-[30px] xl:px-[82px] disabled:bg-primary-300 text-base disabled:cursor-not-allowed font-ff-condensed-pro"
            >
              {{ $t('common.ok') }}
            </button>
          </div>
        </template>
        <template v-else>
          <div class="px-[30px] pt-10">
            <h2 class="text-2xl md:text-[26px] font-normal xl:text-3xl">
              {{ $t('common.forgot_password') }}
            </h2>
            <p class="text-[#404040] mt-2 text-sm xl:text-base">
              {{ $t('common.we_will_send_link_create_new') }}
            </p>
          </div>

          <!-- Error -->
          <div v-show="hasErrors" class="px-[30px]">
            <div class="px-4 py-3 mt-3 bg-red-50 rounded-[10px]">
              <div class="flex items-center">
                <IconsInfoCircle class="mr-2" />
                <div class="text-sm">
                  {{ message }}
                </div>
              </div>
            </div>
          </div>

          <div
            :class="[
              hasErrors
                ? 'xl:pt-6 md:pt-5 pt-[30px]'
                : 'pt-[38px] md:pt-10 xl:pt-12',
              'px-[30px]',
            ]"
          >
            <form @submit.prevent="onSubmit" method="POST">
              <label
                for="email"
                class="text-sm font-medium text-[#0F0F0F] font-suiza-semibold"
              >
                {{ $t('common.signup_email') }}
              </label>
              <div class="relative mt-1 rounded-md shadow-sm">
                <input
                  id="emailRecover"
                  v-model="email"
                  :placeholder="$t('common.please_enter_email')"
                  :class="hasErrors ? 'border-red-500' : 'border-gray-300'"
                  type="email"
                  name="email"
                  class="text-lg block w-full py-3 px-4 rounded-[10px]"
                />
              </div>
              <div v-if="hasErrors" class="mt-1.5 text-sm text-red-500">
                <p v-for="(errorsArray, field) in errorsObj.email" :key="field">
                  {{ errorsArray }}
                </p>
              </div>
            </form>
          </div>

          <div
            class="absolute right-0 left-0 bg-white flex px-[30px] w-full items-center justify-center font-medium bottom-10 font-suiza-semibold"
          >
            <button
              :disabled="loading"
              @click.prevent="onSubmit"
              class="bg-primary-500 w-full md:w-[172px] flex justify-center rounded-[10px] mr-[13px] text-white py-[10px] md:py-2 md:px-[30px] xl:px-[82px] disabled:bg-primary-300 disabled:border text-base disabled:cursor-not-allowed"
            >
              {{ $t('common.submit') }}
            </button>

            <button
              :disabled="loading"
              @click.prevent="closeModal"
              class="rounded-[10px] w-full md:w-[172px] flex justify-center border-gray-300 border bg-white py-[10px] md:py-2 md:px-[30px] xl:px-[82px] disabled:bg-gray-100 text-base disabled:cursor-not-allowed"
            >
              {{ $t('common.cancel') }}
            </button>
          </div>
        </template>
      </div>
    </section>
  </DrawerModal>
</template>

<script setup>
import { useMainStore } from '~/store'
import { useVfm } from 'vue-final-modal'
import IconsInfoCircle from '~/components/icons/InfoCircleIcon.vue'

const nuxtApp = useNuxtApp()
const store = useMainStore()
const vfm = useVfm()

const email = ref('')
const sent = ref(false)
const loading = ref(false)
const message = ref(null)
const errorsObj = ref({})

const hasErrors = computed(() => {
  return Object.keys(errorsObj.value).length > 0
})

function closeModal() {
  vfm.closeAll()
}

async function onSubmit() {
  loading.value = true
  errorsObj.value = {}

  try {
    await nuxtApp
      .$api('api/auth/password/create', {
        method: 'post',
        body: {
          email: email.value,
          country: store.country,
          locale: store.locale,
        },
      })
      .then((response) => {
        console.log(response.data, 'ds')
        nuxtApp.$toast.success(response.data)

        setTimeout(() => {
          loading.value = false
          vfm.closeAll()
        }, 300)
      })
  } catch (error) {
    console.error(error, 'error')

    if (!error._data) {
      throw error
    } else {
      errorsObj.value = error._data.errors
      message.value = error._data.message
    }

    setTimeout(() => {
      loading.value = false
    }, 300)
  }
}
</script>
